<template>
  <a-spin :spinning="isLoading">
    <sdPageHeader :title="$t.customerInfo" />
    <Main>
      <a-row :gutter="25">
        <a-col
          :xxl="6"
          :lg="8"
          :md="10"
          :xs="24"
        >
          <Suspense>
            <template #default>
              <UserCards
                :user="user_info"
                :payment="paymentInfo"
              />
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton
                  avatar
                  active
                  :paragraph="{ rows: 3 }"
                />
              </sdCards>
            </template>
          </Suspense>
          <Suspense>
            <template #default>
              <UserBio :user="user_info" />
            </template>
            <template #fallback>
              <sdCards headless>
                <a-skeleton
                  avatar
                  active
                  :paragraph="{ rows: 3 }"
                />
              </sdCards>
            </template>
          </Suspense>
        </a-col>
        <a-col
          :xxl="18"
          :lg="16"
          :md="14"
          :xs="24"
        >
          <Suspense>
            <SettingWrapper>
              <template #default>
                <div class="coverWrapper">
                  <CoverSection />
                  <nav class="profileTab-menu">
                    <ul>
                      <li>
                        <router-link :to="{ name: 'C.Payment.Page' }">
                          <sdFeatherIcons type="credit-card" />&nbsp; {{ $t.payment }}
                        </router-link>
                      </li>
                      <li>
                        <router-link :to="{ name: 'C.PaymentHistory.Page' }">
                          <sdFeatherIcons type="dollar-sign" />&nbsp; {{ $t.payment_history }}
                        </router-link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </template>
            </SettingWrapper>
          </Suspense>
          <a-row :gutter="25">
            <a-col
              :xxl="16"
              :lg="16"
              :md="16"
              xs="16"
            >
              <Suspense>
                <sdCards headless>
                  <sdHeading
                    as="h8"
                    class="user-info__title"
                    style="color:#9299B8;font-size: 12px"
                  >
                    {{ $t.yourRegisteredCargoCompanies }}
                  </sdHeading>
                  <div class="table-bordered table-responsive">
                    <a-table
                      :columns="addedCompanyColumns"
                      :data-source="customer_cargo_companies"
                      row-key="id"
                      :pagination="false"
                    />
                  </div>
                </sdCards>
              </Suspense>
            </a-col>
            <a-col
              :xxl="8"
              :lg="8"
              :md="8"
              xs="8"
            >
              <Suspense>
                <sdCards headless>
                  <BasicFormWrapper>
                    <a-form
                      :model="formState"
                      @finish="returnSubmit"
                    >
                      <div class="account-form-top">
                        <a-row
                          type="flex"
                          justify="center"
                        >
                          <a-col
                            :xxl="24"
                            :lg="24"
                            :md="24"
                            :xs="24"
                          >
                            <div class="account-form">
                              <a-form-item :label="$t.refundAmount">
                                <a-input v-model:value="returnValue" />
                              </a-form-item>
                              <a-form-button-group>
                                <sdButton
                                  size="default"
                                  type="primary"
                                  @click="returnSubmit"
                                >
                                  {{ $t.requestAReturn }}
                                </sdButton>
                              </a-form-button-group>
                            </div>
                          </a-col>
                        </a-row>
                      </div>
                    </a-form>
                  </BasicFormWrapper>
                </sdCards>
              </Suspense>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </Main>
  </a-spin>
</template>

<script>
import { BasicFormWrapper, Main } from '@/view/styled'
import { notification } from 'ant-design-vue'
import { defineAsyncComponent } from 'vue'
import { SettingWrapper } from '@/view/pages/overview/style'

const UserCards = defineAsyncComponent(() => import('./overview/UserCard'))
const CoverSection = defineAsyncComponent(() => import('./overview/CoverSection'))
const UserBio = defineAsyncComponent(() => import('./overview/UserBio'))


export default {
  name: 'SettingPage',
  components: {
    Main,
    SettingWrapper,
    UserCards,
    CoverSection,
    UserBio,
    BasicFormWrapper,
  },
  data() {
    return {
      isLoading: true,
      addedCompanyColumns: [
        {
          title: this.$t.title,
          dataIndex: 'title',
        },
        {
          title: this.$t.company,
          dataIndex: 'company',
        },
      ],
      returnValue: 0,
      paymentInfo: {},
      customer_cargo_companies: [],
      user_info: {},
    }
  },

  mounted() {
    this.getCustomerInfo()
  },

  methods: {
    returnSubmit() {

    },
    openNotificationWithIcon(type, getMessage, getDescription) {
      notification[type]({
        message: getMessage,
        description: getDescription,
      })
    },
    async getCustomerInfo() {
      this.isLoading = true
      this.$restMethods.postForCargongWebApp('/wallet-page').then(response => {

        if ( ! response.hasError()) {

          const data = response.getData()

          this.paymentInfo = data.payment
          this.user_info = data.user_info
          this.customer_cargo_companies = data.customer_cargo_companies

        }

        this.isLoading = false

      })

    },
  },
}
</script>
